import { Users, DollarSign, ShieldAlert, UserX } from 'lucide-react';

export const problems = [
  {
    icon: Users,
    title: 'Experiencia del Cliente Complicada',
    description:
      'Un proceso de ventas largo y complicado genera un funnel incompleto.',
    impact: '40% menos en clientes que pasan por todo el funnel',
  },
  {
    icon: DollarSign,
    title: 'Baja eficiencia en campañas de marketing',
    description:
      'Leads con bajos niveles de nutrición a raíz de bajos niveles de tasa de apertura',
    impact: '79% de los leads nunca se convierten en ventas',
  },
  {
    icon: ShieldAlert,
    title: 'Falta de personalización',
    description:
      'Mensajes genéricos no conectan emocionalmente con los clientes.',
    impact: 'Bajo engagement de clientes',
  },
  {
    icon: UserX,
    title: 'Procesos de registro manuales',
    description:
      'Procesos de registro manuales y presenciales que no están a favor de las condiciones de los clientes',
    impact: 'Abandono en procesos de registro',
  },
];
