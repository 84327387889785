import { motion } from 'framer-motion';
import { Send } from 'lucide-react';
import { fadeInUp, slideIn } from '../../utils/animations';

export default function MassCommunication() {
  return (
    <div className="py-20 bg-gray-800">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            variants={fadeInUp}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className="space-y-6"
          >
            <div className="inline-flex items-center space-x-2 bg-[#42b6f5]/10 rounded-full px-4 py-2">
              <Send className="w-5 h-5 text-[#42b6f5]" />
              <span className="text-[#42b6f5] font-medium">Comunicación Masiva</span>
            </div>
            <motion.h2
              variants={fadeInUp}
              className="text-[22px] sm:text-4xl font-bold text-white mb-3"
            >
              Comunicación Masiva
            </motion.h2>
            <motion.p
              variants={fadeInUp}
              className="text-[16px] sm:text-xl text-gray-400 max-w-2xl mx-auto mb-6"
            >
              Envía mensajes personalizados a miles de clientes en segundos
            </motion.p>
            <p className="text-gray-300 text-lg">
              Alcanza a miles de clientes con mensajes personalizados a través de WhatsApp.
              Nuestro sistema garantiza una entrega eficiente y un seguimiento detallado de cada campaña.
            </p>
          </motion.div>

          <motion.div
            variants={slideIn}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className="max-w-sm mx-auto"
          >
            <div className="bg-[#E4DCD4] rounded-xl shadow-lg overflow-hidden p-4">
              <img 
                src="https://metalks.co/images/Santi/wa1.png"
                alt="WhatsApp Mass Communication Interface"
                className="w-full rounded-lg"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}