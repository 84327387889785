import React from 'react';
import Hero from './components/sections/Hero';
import Solutions from './components/sections/Solutions';
import WhyWhatsApp from './components/sections/WhyWhatsApp';
import Problems from './components/sections/Problems';
import OmniChannel from './components/sections/OmniChannel';
import Analytics from './components/sections/Analytics';
import PhoneDemo from './components/ui/PhoneDemo';
import CTAButton from './components/ui/CTAButton';
import MassCommunication from './components/sections/MassCommunication';
import ContactForm from './components/sections/ContactForm';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { useState } from 'react';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { title: 'Inicio', url: '/' },
    { title: 'Productos', url: 'https://metalks.co/productos/' },
    { title: 'Contacto', url: 'https://metalks.co/contact-2/' },
    { title: 'Nosotros', url: 'https://metalks.co/about-2/' },
  ];

  return (
    <div className="fixed top-6 left-6 z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-3 bg-black/50 text-white hover:bg-white/10 rounded-lg transition-colors backdrop-blur-sm border border-white/10"
        aria-label="Menu"
      >
        {isOpen ? <X className="w-7 h-7" /> : <Menu className="w-7 h-7" />}
      </button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/60 backdrop-blur-sm"
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ type: "spring", damping: 20 }}
              className="fixed top-20 left-6 bg-black/90 backdrop-blur-md rounded-2xl shadow-2xl border border-white/10 overflow-hidden w-64"
            >
              <nav className="py-2">
                {menuItems.map((item) => (
                  <a
                    key={item.url}
                    href={item.url}
                    target={item.url === '/' ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                    className="flex items-center px-8 py-4 text-lg text-white hover:bg-white/10 transition-colors"
                    onClick={() => setIsOpen(false)}
                  >
                    {item.title}
                  </a>
                ))}
              </nav>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

function App() {
  return (
    <div className="min-h-screen bg-gray-900">
      {/* Fixed Get Started Button */}
      <div className="fixed top-4 right-4 z-50 hidden md:block">
        <CTAButton
          text="Ingreso plataforma"
          href="https://portal.metalks.co/"
          className="!py-2 !px-6 text-sm"
        />
      </div>

      <Navigation />
      <Hero />
      <PhoneDemo />
      <Solutions />
      <WhyWhatsApp />
      <Problems />
      <OmniChannel />
      <Analytics />
      <MassCommunication />
      <ContactForm />
    </div>
  );
}

export default App;
