import { motion, AnimatePresence } from 'framer-motion';
import { Check, Send, Bot, ShieldCheck } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

const Message = ({ children, isVisible, isUser = false }) => {
  const forceScrollDown = () => {
    const container = document.querySelector('.messages-container-mobile');
    if (container) {
      container.scrollTop = 999999999;
      container.scrollTop = container.scrollHeight + 10000;
      requestAnimationFrame(() => {
        container.scrollTop = 999999999;
      });
    }
  };

  useEffect(() => {
    if (isVisible) {
      forceScrollDown();
    }
  }, [isVisible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.5 }}
          className={`${
            isUser ? 'ml-auto bg-[#e7ffdb]' : 'mr-auto bg-white'
          } rounded-lg p-3 max-w-[85%] mb-3 shadow-sm text-base`}
          onAnimationComplete={forceScrollDown}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default function WhatsAppDemoMobile() {
  const containerRef = useRef(null);
  const [visibleMessages, setVisibleMessages] = useState([0, 1]); // Solo mostramos 2 mensajes
  const [isResetting, setIsResetting] = useState(false);

  const messages = [
    {
      id: 0,
      content: (
        <div className="space-y-2">
          <div className="text-blue-600 flex items-center gap-1">
            <Send className="w-5 h-5" />
            <span>Campaña automática</span>
          </div>
          <div className="text-base">Gracias por llenar el formulario. Por favor, envía una foto de tu documento de identidad.</div>
        </div>
      ),
      isUser: false
    },
    {
      id: 1,
      content: (
        <div className="space-y-2">
          <img
            src="https://metalks.co/images/cedulafoto.jpg"
            alt="DNI"
            className="rounded-md w-full object-cover h-[120px]"
          />
          <div className="text-sm text-gray-600">Documento de identidad</div>
        </div>
      ),
      isUser: true
    },
    {
      id: 2,
      content: (
        <div className="space-y-2">
          <div className="text-purple-600 flex items-center gap-1">
            <Bot className="w-5 h-5" />
            <span>Chatbot personalizado</span>
          </div>
          <div className="text-base">Gracias, ahora por favor envía una foto de tu rostro.</div>
        </div>
      ),
      isUser: false
    },
    {
      id: 3,
      content: (
        <div className="space-y-2">
          <img
            src="https://metalks.co/images/Imagenrostro.jpg"
            alt="Rostro"
            className="rounded-md w-full object-cover h-[120px]"
          />
          <div className="text-sm text-gray-600">Foto de rostro</div>
        </div>
      ),
      isUser: true
    },
    {
      id: 4,
      content: (
        <div className="space-y-2">
          <div className="text-green-600 flex items-center gap-1">
            <ShieldCheck className="w-5 h-5" />
            <span>Validación de identidad</span>
          </div>
          <div>Procederemos con tu registro</div>
        </div>
      ),
      isUser: false
    }
  ];

  // Efecto para mostrar mensajes secuencialmente
  useEffect(() => {
    if (isResetting) return;

    const interval = setInterval(() => {
      setVisibleMessages(prev => {
        const nextMessageId = prev[1] + 1;
        if (nextMessageId >= messages.length) {
          setIsResetting(true);
          return prev;
        }
        return [prev[1], nextMessageId]; // Mostramos el mensaje actual y el siguiente
      });
    }, 6000); // Reducido a 6 segundos (antes 8)

    return () => clearInterval(interval);
  }, [isResetting]);

  // Efecto para reiniciar la secuencia
  useEffect(() => {
    if (!isResetting) return;

    const timer = setTimeout(() => {
      setVisibleMessages([0, 1]);
      setIsResetting(false);
    }, 4000); // Reducido a 4 segundos (antes 5)

    return () => clearTimeout(timer);
  }, [isResetting]);

  return (
    <div className="h-full bg-[url('https://i.pinimg.com/originals/97/c0/07/97c00759d90d786d9b6096d274ad3e07.png')] bg-cover p-4 max-w-[400px] w-full mx-auto">
      {/* Header */}
      <div className="bg-[#1a1a1a] -mx-4 -mt-4 px-4 py-3 flex items-center gap-3 mb-4">
        <div className="w-10 h-10 rounded-full bg-[#42b6f5] flex items-center justify-center overflow-hidden">
          <img
            src="https://metalks.co/images/metalkslogowhite.png"
            alt="Metalks"
            className="w-7 h-7 object-contain"
          />
        </div>
        <div>
          <h3 className="text-white text-base font-medium">Metalks Assistant</h3>
          <p className="text-white/60 text-sm">En línea</p>
        </div>
      </div>

      <div className="h-[500px] overflow-hidden">
        <div 
          ref={containerRef}
          className="h-[500px] overflow-hidden no-scrollbar messages-container-mobile"
        >
          {messages.map((message, index) => (
            <Message 
              key={message.id}
              isVisible={visibleMessages.includes(message.id)}
              isUser={message.isUser}
            >
              {message.content}
            </Message>
          ))}
        </div>
      </div>

      {/* Input area */}
      <div className="bg-[#1a1a1a] -mx-4 -mb-4 p-4 mt-4">
        <div className="bg-white rounded-full py-2 px-4 flex items-center gap-2">
          <input
            type="text"
            placeholder="Escribe un mensaje"
            className="flex-1 outline-none text-sm"
          />
          <Send className="w-5 h-5 text-[#42b6f5]" />
        </div>
      </div>
    </div>
  );
}
