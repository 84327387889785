import {
  MessageSquare,
  Shield,
  FileCheck,
  CreditCard,
  Users,
  Bot,
} from 'lucide-react';

export const solutions = [
  {
    icon: MessageSquare,
    title: 'Campañas de tipo marketing',
    description:
      'Campañas personalizadas por WhatsApp que impulsen tus ventas y procesos de nutrición de lead',
    metrics: ['90% de tasa de lectura', 'Mayor engagement'],
  },
  {
    icon: Shield,
    title: 'Validación de Identidad Digital',
    description:
      'Proceso KYC automatizado a través de WhatsApp con validación de documentos en tiempo real. Solo necesita una Selfie y foto de documento',
    metrics: ['60% más rápido', 'Mayor seguridad'],
  },
  {
    icon: Bot,
    title: 'Automatización de Comunicaciones',
    description:
      'Automatiza el envío de notificaciones, recordatorios y seguimientos con mensajes personalizados acorde con acciones de clientes',
    metrics: ['90% nutrición de lead', 'Mayor eficiencia'],
  },
  {
    icon: CreditCard,
    title: 'Integración con aplicaciones',
    description:
      'Integra tus aplicaciones y con la ayuda de Metalks conecta tu CRM, Funnels y agendas',
    metrics: ['Más de 100 aplicaciones disponibles', 'Mayor integración'],
  },
  {
    icon: Users,
    title: 'Registro Digital automatizado',
    description:
      'Proceso de registro simplificado con recolección de documentos vía WhatsApp',
    metrics: ['80% más rápido', 'Mejor conversión'],
  },
  {
    icon: Bot,
    title: 'Atención con agentes de IA',
    description: 'Chatbots inteligentes que manejan consultas y reclamos 24/7',
    metrics: ['90% automatización', 'Respuesta inmediata'],
  },
];
