import React from 'react';
import { motion } from 'framer-motion';
import StarField from '../ui/StarField';
import RotatingText from '../ui/RotatingText';
import CTAButton from '../ui/CTAButton';

const ShootingStar = () => (
  <motion.div
    initial={{ x: -100, y: -100, opacity: 0 }}
    animate={{
      x: [null, window.innerWidth + 100],
      y: [null, window.innerHeight + 100],
      opacity: [0, 1, 1, 0],
    }}
    transition={{
      duration: 2,
      times: [0, 0.1, 0.9, 1],
      repeat: Infinity,
      repeatDelay: 3,
    }}
    className="absolute w-1 h-1 bg-[#42b6f5]"
    style={{
      boxShadow: '0 0 20px 2px #42b6f5',
      filter: 'blur(1px)',
    }}
  />
);

export default function Hero() {
  return (
    <div className="relative min-h-[80vh] sm:min-h-screen bg-black">
      {/* Fondo de estrellas */}
      <StarField />

      {/* Contenido principal */}
      <div className="relative z-10 container mx-auto px-6 pt-28 pb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-5xl mx-auto"
        >
          {/* Logo de Metalks en lugar del ícono */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
            className="flex justify-center mb-8"
          >
            <img
              src="https://metalks.co/images/Metalkslogocompletowhite.png"
              alt="Logo de Metalks"
              className="w-32 md:w-40 h-auto"
            />
          </motion.div>

          {/* Título principal */}
          <motion.h1
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1, type: 'spring' }}
            className="text-[1.75rem] sm:text-4xl md:text-6xl font-bold text-white mb-6 sm:mb-8 leading-[1.2]"
          >
            <div className="flex flex-wrap items-center justify-center gap-x-2 sm:gap-x-3 gap-y-0.5 sm:gap-y-1">
              <span>Transforma tu sector</span>
              <RotatingText />
              <span>con WhatsApp y automatizaciones</span>
              <span className="text-[#42b6f5]">creadas a tu medida</span>
            </div>
          </motion.h1>

          {/* Descripción */}
          <p className="text-gray-300 text-sm sm:text-lg md:text-xl mb-6 sm:mb-10 max-w-3xl mx-auto">
            Mejora la tasa de lectura de tus campañas de marketing directo enviando campañas en WhatsApp
          </p>

          {/* Botón CTA */}
          <CTAButton
            text="Agenda una asesoría gratis"
            href="https://metalks.co/contact-2/"
            className="text-sm sm:text-lg px-5 py-2 sm:px-8 sm:py-3"
          />
        </motion.div>
      </div>
    </div>
  );
}
