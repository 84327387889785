import { useState } from 'react';
import { motion } from 'framer-motion';
import { Mail, Send } from 'lucide-react';
import { fadeInUp } from '../../utils/animations';
import emailjs from 'emailjs-com';

export default function ContactForm() {
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const email = e.target.Email.value; // Campo 'Email' del formulario
    if (!validateEmail(email)) {
      setErrorMessage('Por favor, ingresa un email válido.');
      return;
    }

    setIsSending(true);

    emailjs
      .sendForm(
        'service_u94qv2n',
        'template_gcix72x',
        e.target,
        'DY94y4hKG44nFqUTr'
      )
      .then(
        (result) => {
          console.log('Mensaje enviado:', result.text);
          alert('¡Mensaje enviado con éxito!');
          setErrorMessage('');
        },
        (error) => {
          console.error('Error al enviar:', error.text);
          alert('Hubo un problema al enviar el mensaje. Intenta de nuevo.');
        }
      )
      .finally(() => {
        setIsSending(false);
        e.target.reset();
      });
  };

  return (
    <div className="py-20 bg-gray-900">
      <div className="max-w-4xl mx-auto px-4">
        <motion.div
          variants={fadeInUp}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <div className="flex justify-center mb-6">
            <Mail className="w-16 h-16 text-[#42b6f5]" />
          </div>
          <motion.h2
            variants={fadeInUp}
            className="text-[22px] sm:text-4xl font-bold text-white mb-3"
          >
            Contáctanos
          </motion.h2>
          <motion.p
            variants={fadeInUp}
            className="text-[16px] sm:text-xl text-gray-400 max-w-2xl mx-auto mb-6"
          >
            ¿Listo para transformar tu comunicación con WhatsApp?
          </motion.p>
        </motion.div>

        <motion.div
          variants={fadeInUp}
          initial="initial"
          whileInView="animate"
          viewport={{ once: true }}
          className="bg-gray-800 rounded-xl p-8 shadow-xl"
        >
          <form className="space-y-6" onSubmit={sendEmail}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="Nombre"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  id="Nombre"
                  name="Nombre" // Coincide con {{Nombre}}
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#42b6f5] focus:border-transparent"
                  placeholder="Tu nombre"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="Email"
                  className="block text-sm font-medium text-gray-300 mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="Email"
                  name="Email" // Coincide con {{Email}}
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#42b6f5] focus:border-transparent"
                  placeholder="tu@email.com"
                  required
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="Empresa"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Empresa
              </label>
              <input
                type="text"
                id="Empresa"
                name="Empresa" // Coincide con {{Empresa}}
                className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#42b6f5] focus:border-transparent"
                placeholder="Nombre de tu empresa"
              />
            </div>

            <div>
              <label
                htmlFor="Mensaje"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Mensaje
              </label>
              <textarea
                id="Mensaje"
                name="Mensaje" // Coincide con {{Mensaje}}
                rows={4}
                className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#42b6f5] focus:border-transparent"
                placeholder="¿Cómo podemos ayudarte?"
                required
              />
            </div>

            {errorMessage && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}

            <div>
              <button
                type="submit"
                disabled={isSending}
                className={`inline-flex items-center justify-center w-full md:w-auto px-8 py-3 ${
                  isSending
                    ? 'bg-gray-600 cursor-not-allowed'
                    : 'bg-[#42b6f5] hover:bg-[#3aa5e0]'
                } text-white font-semibold rounded-lg transition-colors`}
              >
                {isSending ? 'Enviando...' : 'Enviar Mensaje'}
              </button>
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
}
