import { motion } from 'framer-motion';
import { IPhoneMockup } from './IPhoneMockup';
import WhatsAppDemo from './WhatsAppDemo';
import WhatsAppDemoMobile from './WhatsAppDemoMobile';
import { fadeInUp, slideIn } from '../../utils/animations';
import { MessageCircle, Check } from 'lucide-react';
import StarField from '../ui/StarField';
import { useEffect, useState } from 'react';

export default function PhoneDemo() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="py-16 bg-black relative">
      <StarField />
      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="max-w-[280px] mx-auto md:max-w-sm"
          >
            <IPhoneMockup>
              {isMobile ? <WhatsAppDemoMobile /> : <WhatsAppDemo />}
            </IPhoneMockup>
          </motion.div>

          <motion.div
            variants={slideIn}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className="space-y-6 max-w-2xl"
          >
            <div className="inline-flex items-center space-x-2 bg-[#42b6f5]/10 rounded-full px-3 py-1.5">
              <MessageCircle className="w-4 h-4 text-[#42b6f5]" />
              <span className="text-[#42b6f5] text-sm font-medium">
                WhatsApp Business API
              </span>
            </div>
            <h3 className="text-2xl md:text-3xl font-bold text-white leading-tight">
              Envía campañas de marketing en WhatsApp a tus clientes
            </h3>
            <p className="text-base md:text-lg text-gray-300 leading-relaxed">
              La plataforma con mejor tasa de lectura de tus campañas de
              marketing. Enviadas a tus clientes en su canal más utilizado
            </p>
            <ul className="space-y-3 md:space-y-4 mt-6">
              {[
                'Comunicación bidireccional automatizada',
                'Mensajes masivos personalizados',
                'Integración con sistemas existentes (CRM, Agendas, Funnels)',
                'Metalks apoya en el proceso de creación del Bot',
                'Verificación oficial de WhatsApp',
              ].map((feature) => (
                <li key={feature} className="flex items-start space-x-3">
                  <Check className="w-4 h-4 md:w-5 md:h-5 text-[#42b6f5] mt-1 flex-shrink-0" />
                  <span className="text-base md:text-lg text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
