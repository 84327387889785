import { motion } from 'framer-motion';

const ShootingStar = () => (
  <motion.div
    initial={{ x: -100, y: -100, opacity: 0 }}
    animate={{
      x: [null, window.innerWidth + 100],
      y: [null, window.innerHeight + 100],
      opacity: [0, 1, 1, 0],
    }}
    transition={{
      duration: 2,
      times: [0, 0.1, 0.9, 1],
      repeat: Infinity,
      repeatDelay: 3,
    }}
    className="absolute w-1 h-1 bg-[#42b6f5]"
    style={{
      boxShadow: '0 0 20px 2px #42b6f5',
      filter: 'blur(1px)',
    }}
  />
);

export default function StarField() {
  const stars = Array.from({ length: 100 }).map((_, i) => ({
    id: i,
    size: Math.random() * 2,
    x: Math.random() * 100,
    y: Math.random() * 100,
    duration: 0.5 + Math.random() * 2,
  }));

  return (
    <div className="absolute inset-0 overflow-hidden">
      {stars.map((star) => (
        <motion.div
          key={star.id}
          className="absolute bg-white rounded-full"
          style={{
            width: star.size,
            height: star.size,
            left: `${star.x}%`,
            top: `${star.y}%`,
          }}
          animate={{
            opacity: [0.2, 1, 0.2],
          }}
          transition={{
            duration: star.duration,
            repeat: Infinity,
            ease: 'linear',
          }}
        />
      ))}
      <ShootingStar />
    </div>
  );
}
