import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

interface IPhoneMockupProps {
  children: React.ReactNode;
}

export const IPhoneMockup: React.FC<IPhoneMockupProps> = ({ children }) => {
  const chatContentRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (chatContentRef.current) {
      if (isFirstRender.current) {
        chatContentRef.current.scrollTop = 0;
        isFirstRender.current = false;
      } else {
        const scrollToBottom = () => {
          if (chatContentRef.current) {
            chatContentRef.current.scrollTo({
              top: chatContentRef.current.scrollHeight,
              behavior: 'smooth',
            });
          }
        };
        setTimeout(scrollToBottom, 100);
      }
    }
  }, [children]);

  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="relative mx-auto w-[280px] h-[570px] md:w-[320px] md:h-[650px] bg-black rounded-[2rem] md:rounded-[3rem] border-[3px] md:border-4 border-white/10
                 shadow-2xl shadow-[#42b6f5]/10"
    >
      {/* Notch */}
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-28 md:w-40 h-5 md:h-7 bg-black rounded-b-2xl md:rounded-b-3xl" />

      {/* Dynamic Island */}
      <div className="absolute top-1.5 md:top-2 left-1/2 transform -translate-x-1/2 w-20 md:w-24 h-[20px] md:h-[25px] bg-black rounded-full" />

      {/* Screen */}
      <div className="absolute top-0 left-0 right-0 bottom-0 m-2 md:m-3 bg-black rounded-[2rem] md:rounded-[2.5rem] overflow-hidden">
        {/* Status Bar */}
        <div className="relative h-12 bg-[#1a1a1a] flex items-center justify-between px-6 text-white text-xs">
          <span>9:41</span>
          <div className="absolute top-1.5 left-1/2 transform -translate-x-1/2 w-16 h-[18px] bg-black rounded-full" />
          <span>5G</span>
        </div>

        {/* Chat Content */}
        <div
          ref={chatContentRef}
          className="h-[calc(100%-3rem)] overflow-y-auto bg-[#0c1317] scrollbar-none"
        >
          {children}
        </div>
      </div>
    </motion.div>
  );
};

export default IPhoneMockup;
