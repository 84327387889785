import { motion, AnimatePresence } from 'framer-motion';
import { Check, Send, Bot, ShieldCheck } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

const Message = ({ children, isVisible, isUser = false }) => {
  const forceScrollDown = () => {
    const container = document.querySelector('.messages-container');
    if (container) {
      // Intento inicial super agresivo
      container.scrollTop = 999999999;
      container.scrollTop = container.scrollHeight + 10000;
      
      // Serie de intentos rápidos con valores diferentes
      for (let i = 0; i < 8; i++) {
        setTimeout(() => {
          container.scrollTop = 999999999;
          container.scrollTop = container.scrollHeight + 10000;
          requestAnimationFrame(() => {
            container.scrollTop = 999999999;
            container.scrollTop = container.scrollHeight + 10000;
          });
        }, i * 10); // Super rápido, cada 10ms
      }
    }
  };

  // Efecto para forzar scroll cuando el mensaje se hace visible
  useEffect(() => {
    if (isVisible) {
      // Múltiples intentos en diferentes momentos
      forceScrollDown();
      requestAnimationFrame(forceScrollDown);
      setTimeout(forceScrollDown, 50);
      setTimeout(forceScrollDown, 100);
      setTimeout(forceScrollDown, 150);
    }
  }, [isVisible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          transition={{ duration: 0.05 }} // Ultra rápido
          className={`${
            isUser ? 'ml-auto bg-[#e7ffdb]' : 'mr-auto bg-white'
          } rounded-lg p-3 max-w-[85%] mb-3 shadow-sm text-base`}
          onAnimationStart={forceScrollDown}
          onAnimationComplete={forceScrollDown}
          onUpdate={forceScrollDown}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default function WhatsAppDemo() {
  const containerRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(1);
  const [isResetting, setIsResetting] = useState(false);

  // Efecto para mostrar mensajes secuencialmente
  useEffect(() => {
    const messageInterval = 2000; // 2 segundos entre mensajes

    const interval = setInterval(() => {
      if (isResetting) {
        setVisibleCount(0);
        setIsResetting(false);
        if (containerRef.current) {
          containerRef.current.scrollTop = 0;
        }
        return;
      }

      setVisibleCount(prev => {
        if (prev >= 5) { // Total de mensajes
          setIsResetting(true);
          return prev;
        }
        return prev + 1;
      });
    }, messageInterval);

    return () => clearInterval(interval);
  }, [isResetting]);

  // Efecto para auto-scroll instantáneo cuando aparece un nuevo mensaje
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    container.scrollTop = 999999999; // Valor extremadamente alto para garantizar scroll máximo
  }, [visibleCount]);

  return (
    <div className="h-full bg-[url('https://i.pinimg.com/originals/97/c0/07/97c00759d90d786d9b6096d274ad3e07.png')] bg-cover p-4 max-w-[400px] w-full mx-auto">
      {/* Header */}
      <div className="bg-[#1a1a1a] -mx-4 -mt-4 px-4 py-3 flex items-center gap-3 mb-4">
        <div className="w-10 h-10 rounded-full bg-[#42b6f5] flex items-center justify-center overflow-hidden">
          <img
            src="https://metalks.co/images/metalkslogowhite.png"
            alt="Metalks"
            className="w-7 h-7 object-contain"
          />
        </div>
        <div>
          <h3 className="text-white text-base font-medium">Metalks Assistant</h3>
          <p className="text-white/60 text-sm">En línea</p>
        </div>
      </div>

      <div className="h-[500px] overflow-hidden">
        <div 
          ref={containerRef} 
          className="h-[500px] overflow-hidden no-scrollbar messages-container"
        >
          <Message isVisible={visibleCount >= 1}>
            <div className="space-y-2">
              <div className="text-blue-600 flex items-center gap-1">
                <Send className="w-5 h-5" />
                <span>Campaña automática</span>
              </div>
              <div className="text-base">Gracias por llenar el formulario. Por favor, envía una foto de tu documento de identidad.</div>
            </div>
          </Message>

          <Message isVisible={visibleCount >= 2} isUser>
            <div className="space-y-2">
              <img
                src="https://metalks.co/images/cedulafoto.jpg"
                alt="DNI"
                className="rounded-md w-full object-cover h-[120px]"
              />
              <div className="text-sm text-gray-600">Documento de identidad</div>
            </div>
          </Message>

          <Message isVisible={visibleCount >= 3}>
            <div className="space-y-2">
              <div className="text-purple-600 flex items-center gap-1">
                <Bot className="w-5 h-5" />
                <span>Chatbot personalizado</span>
              </div>
              <div className="text-base">Gracias, ahora por favor envía una foto de tu rostro.</div>
            </div>
          </Message>

          <Message isVisible={visibleCount >= 4} isUser>
            <div className="space-y-2">
              <img
                src="https://metalks.co/images/Imagenrostro.jpg"
                alt="Rostro"
                className="rounded-md w-full object-cover h-[120px]"
              />
              <div className="text-sm text-gray-600">Foto de rostro</div>
            </div>
          </Message>

          <Message isVisible={visibleCount >= 5}>
            <div className="space-y-2">
              <div className="text-green-600 flex items-center gap-1">
                <ShieldCheck className="w-5 h-5" />
                <span>Validación de identidad</span>
              </div>
              <div>Procederemos con tu registro</div>
            </div>
          </Message>
        </div>
      </div>
    </div>
  );
}
